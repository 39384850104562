import {
  ResourcesUiState,
  ResourceUiStoreContext,
  useResourcesUiStore,
} from '@/src/modules/resources/stores/useResourcesUiStore';
import React, { createContext, ReactNode, useContext } from 'react';

type ResourcesPreviewUiConfiContextProps = {
  appearence: ResourcesUiState;
};

// Create the context
const ResourcesPreviewUiConfigContext = createContext<ResourcesPreviewUiConfiContextProps>(
  undefined as unknown as ResourcesPreviewUiConfiContextProps,
);

interface ResourcesPreviewProviderProps {
  /**
   * this affects the appearence of the preview - zoom level and such per context
   */
  uiContext?: ResourceUiStoreContext;
  children: ReactNode;
}
// Create the provider component
export const ResourcesPreviewUiConfigProvider: React.FC<ResourcesPreviewProviderProps> = ({
  uiContext = 'global',
  children,
}) => {
  const appearence = useResourcesUiStore(uiContext);
  return (
    <ResourcesPreviewUiConfigContext.Provider value={{ appearence }}>
      {children}
    </ResourcesPreviewUiConfigContext.Provider>
  );
};

// Custom hook to use the context
export const useResourcePreviewUiConfigContext = () => useContext(ResourcesPreviewUiConfigContext);
