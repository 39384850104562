import FolderIcon from '@/public/images/icons/Folder.png';
import { useNewResourceContext } from '@/src/modules/resources/components/NewResource/context/ModalNewResourceContext';
import { mediaMobile } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import React from 'react';
import styled from 'styled-components';

const DestinationButtonBase = styled.button`
  width: 200px;
  border-radius: 40px;
  border: 1px dashed ${cssVar['color-border-primary']};
  background: ${cssVar['color-bg-secondary']};
  display: flex;
  height: 38px;
  padding: 10px 16px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;

  > span {
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: start;
  }

  position: absolute;
  bottom: -70px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.3);
  color: white;
  transition: 0.2s all;
  &:hover {
    background: rgba(0, 0, 0, 0.33);
  }
  ${mediaMobile} {
    position: static;
    min-width: 200px;
    width: auto;
    flex-grow: 1;
    margin-right: auto;
    transform: none;
    color: ${cssVar['color-text-primary']};
    background: none;
  }
`;

export const DestinationButton = React.forwardRef<
  React.ElementRef<typeof DestinationButtonBase>,
  React.ComponentPropsWithoutRef<typeof DestinationButtonBase>
>((props, ref) => {
  const { destinationParent, handleOpenDestinationFolder } = useNewResourceContext();

  return (
    <DestinationButtonBase
      data-testid="destination-button"
      onClick={handleOpenDestinationFolder}
      type="button"
      ref={ref}
      {...props}
    >
      <img src={FolderIcon.src} alt="Folder" width={16} />
      <span>{destinationParent?.name}</span>
    </DestinationButtonBase>
  );
});

DestinationButton.displayName = 'DestinationButton';
