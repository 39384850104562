import InvertedFabricIcon from '@/public/images/icons/InvertedFabric.svg';
import { Flex } from '@/src/modules/ui/components/Flex';
import { PProps, SPAN } from '@/src/modules/ui/components/Typography';
import { cssTextEllipsis } from '@/src/modules/ui/styled-utils';
import React from 'react';
import styled from 'styled-components';

const PathContainer = styled(Flex).attrs({
  alignItems: 'center',
})`
  gap: 0.5rem;
  overflow: hidden;
`;

const NodePathName = styled(SPAN)`
  white-space: nowrap;
  ${cssTextEllipsis};
`;

interface PathItemWithTitle {
  id: string;
  title: string;
}

interface PathItemWithName {
  id: string;
  name: string;
}

type PathItem = PathItemWithTitle | PathItemWithName;

export const ResourcePath: React.FC<{
  path?: PathItem[] | null;
  className?: string;
  showFabricRoot?: boolean;
  color?: PProps['color'];
}> = ({ path, className, showFabricRoot = true, color = 'tertiary' }) => {
  const renderedPath: PathItem[] = path || [];

  return (
    <PathContainer className={className}>
      {renderedPath.length > 0 && showFabricRoot && (
        <InvertedFabricIcon
          width={22.5}
          height={19.5}
          color={'var(--fabric-color-app-primary-text'}
        />
      )}
      {renderedPath.map((node, index) => {
        const title = 'title' in node ? node.title : node.name;
        return (
          <React.Fragment key={node.id}>
            {(index > 0 || showFabricRoot) && <SPAN color="tertiary">/</SPAN>}
            <NodePathName color={color} weight={500}>
              {index === 0 || index === renderedPath.length - 1 ? title : '...'}
            </NodePathName>
          </React.Fragment>
        );
      })}
    </PathContainer>
  );
};
