import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SuggestionContext, SuggestionItem, SuggestionItemType } from '../magic.types';
import useDebounce from '@/src/hooks/debounce';
import { useQueryMagicSuggestions } from '../queries/useQueryMagicSuggestions';
import { simpleHash } from '@/src/utils/text';
import { noop } from '@/src/utils/noop';
import { createOptimisticPrivateTag } from '../../tags/utils/createOptimisticPrivateTag';

interface MagicSuggestionContextProps {
  /**
   * Can be used by children to rerender smoothly when the suggestions change.
   */
  listHash: number | null;
  /**
   * The filtered suggestions available.
   */
  suggestions: SuggestionItem[] | null;
  /**
   * Will pop the suggestion from the list, create it (if non existent) and run
   * the select tag FN in the provider.
   */
  onSelectSuggestion: (suggestion: SuggestionItem) => void;
  /**
   * Will simply pop a suggestion, locking the list.
   */
  popSuggestion: (suggestion: SuggestionItem) => void;
}

const MagicSuggestionsContext = React.createContext<MagicSuggestionContextProps>({
  listHash: null,
  suggestions: null,
  onSelectSuggestion: noop,
  popSuggestion: noop,
} as MagicSuggestionContextProps);

interface MagicSuggestionProviderProps {
  /**
   * The context info that is used to suggest new tags/locations.
   * Internally debounced.
   */
  context?: SuggestionContext;

  /**
   * Called whenever a suggestion is selected, this is after it is popped from the suggestion list.
   */
  onSelectSuggestion?: (suggestion: SuggestionItem) => void;

  /**
   * If the suggestions should include folders.
   */
  includeFolders?: boolean;

  /**
   * Keep previous suggestions when context changes.
   */
  keepPrevious?: boolean;

  children: React.ReactNode;
}

const MagicSuggestionsContextProvider: React.FC<MagicSuggestionProviderProps> = ({
  context: contextProp,
  onSelectSuggestion: onSelectSuggestionProp,
  includeFolders,
  keepPrevious = true,
  children,
}) => {
  const context = useDebounce(contextProp, 500);

  const { data: remoteSuggestions, isLoading: suggestionsLoading } =
    useQueryMagicSuggestions(context);

  // ── The hash prevents layout animations from overlapping in a weird way ──
  const [listHash, setListHash] = useState<number | null>(null);

  // ──────────────────────────────────────────────────────────────────────
  // Locking after the user takes an action to prevent the same suggestion from showing up again
  // ──────────────────────────────────────────────────────────────────────
  const [locked, setLocked] = useState<boolean>(false);

  const [suggestions, setSuggestions] = useState<SuggestionItem[] | null>(null);

  useEffect(() => {
    if (keepPrevious) return;
    setSuggestions([]);
  }, [context, keepPrevious]);

  useEffect(() => {
    if (locked || !remoteSuggestions || suggestionsLoading) return;

    const tagsExisting = (remoteSuggestions?.tagsExisting || []).map((t) => ({
      ...t,
      type: SuggestionItemType.TAG,
      tag: t,
    }));

    const tagsNew = (remoteSuggestions?.tagsNew || []).map((t) => {
      const optimisticTag = createOptimisticPrivateTag(t.name);

      return {
        ...t,
        id: optimisticTag.id,
        type: SuggestionItemType.TAG,
        tag: optimisticTag,
        draftReason: 'suggestion',
      };
    });

    const folders = !includeFolders
      ? []
      : (remoteSuggestions?.folders || []).map((t) => ({
          ...t,
          type: SuggestionItemType.FOLDER,
        }));

    const tags = [...tagsExisting, ...tagsNew].slice(0, folders.length > 0 ? 2 : 3);

    const suggestionOptions = [...tags, ...folders].slice(0, 3);
    setSuggestions(suggestionOptions);
    setListHash(simpleHash(JSON.stringify(suggestionOptions)));
  }, [includeFolders, locked, remoteSuggestions, suggestionsLoading]);

  const popSuggestion = useCallback((el: SuggestionItem) => {
    setLocked(true);
    setSuggestions((prev) =>
      (prev ?? []).filter((s) => !((s.id ?? s.name) === (el.id ?? el.name) && s.type === el.type)),
    );
  }, []);

  const onSelectSuggestion = useCallback(
    async (el: SuggestionItem) => {
      popSuggestion(el);
      onSelectSuggestionProp?.(el);
    },
    [onSelectSuggestionProp, popSuggestion],
  );

  const value = useMemo<MagicSuggestionContextProps>(
    () => ({
      listHash,
      suggestions,
      onSelectSuggestion,
      popSuggestion,
    }),
    [listHash, onSelectSuggestion, suggestions, popSuggestion],
  );

  return (
    <MagicSuggestionsContext.Provider value={value}>{children}</MagicSuggestionsContext.Provider>
  );
};

const useMagicSuggestionsContext = () => React.useContext(MagicSuggestionsContext);

export { MagicSuggestionsContextProvider, useMagicSuggestionsContext };
