import { ApiColorLabel } from '@/src/modules/labels/labels.types';
import {
  ResourceDetail,
  ResourceDetailAudio,
  ResourceDetailBookmark,
  ResourceDetailDefault,
  ResourceDetailDocument,
  ResourceDetailFolder,
  ResourceDetailHighlight,
  ResourceDetailImage,
  ResourceDetailNotepad,
  ResourceDetailVideo,
} from '@/src/modules/resources/resources.types';
import {
  isResourceDetailAudio,
  isResourceDetailBookmark,
  isResourceDetailDefault,
  isResourceDetailDocument,
  isResourceDetailFolder,
  isResourceDetailHighlight,
  isResourceDetailImage,
  isResourceDetailImageBookmark,
  isResourceDetailNotepad,
  isResourceDetailVideo,
} from '@/src/modules/resources/utils/resourceDetailGuards';
import { OptimisticDraft } from '@/src/types/draftable';
import React from 'react';

interface ExtendedProps {
  /**
   * if following is disabled:
   * - delete
   * - move
   * - manage sharing
   */
  readOnly: boolean;
  handleDownload: (() => Promise<void>) | undefined;
  /**
   * this property combines the name and the extension of the resource
   */
  fullName: string;
  placeholderName: string;
  isRenameable: boolean;
  parentColorLabels: ApiColorLabel[] | undefined;
}

export interface ResourceDataContextProps extends ExtendedProps {
  resource?: OptimisticDraft<ResourceDetail>;
}

interface ResourceDataContextSafeProps<
  T extends OptimisticDraft<ResourceDetail> = OptimisticDraft<ResourceDetail>,
> extends ExtendedProps {
  resource: T;
}

export const ResourceDataContext = React.createContext<ResourceDataContextProps>(
  {} as ResourceDataContextProps,
);

export const useResourceDataContext = () => React.useContext(ResourceDataContext);

/**
 * use this hook only inside components which you're sure that resource is not null
 * e.g. components which are guarded some loading state
 * otherwise use useResourceDataContext
 */
export const useResourceDataContextSafe = () => {
  const context = useResourceDataContext();
  if (!context.resource) {
    throw new Error('ResourceDataContext is used without a resource outside guarded area');
  }

  return context as ResourceDataContextSafeProps;
};

export const useResourceBookmarkDataSafe = () => {
  const context = useResourceDataContextSafe();
  if (!isResourceDetailBookmark(context.resource)) {
    throw new Error('Not a bookmark');
  }

  return context as ResourceDataContextSafeProps<OptimisticDraft<ResourceDetailBookmark>>;
};

export const useResourceHighlightDataSafe = () => {
  const context = useResourceDataContextSafe();
  if (!isResourceDetailHighlight(context.resource)) {
    throw new Error('Not a highlight');
  }

  return context as ResourceDataContextSafeProps<OptimisticDraft<ResourceDetailHighlight>>;
};

export const useResourceImageDataSafe = () => {
  const context = useResourceDataContextSafe();
  if (!isResourceDetailImage(context.resource)) {
    throw new Error('Not an image resource');
  }

  return context as ResourceDataContextSafeProps<OptimisticDraft<ResourceDetailImage>>;
};

export const useResourceVideoDataContextSafe = () => {
  const context = useResourceDataContextSafe();
  if (!isResourceDetailVideo(context.resource)) {
    throw new Error('Not a video resource');
  }

  return context as ResourceDataContextSafeProps<OptimisticDraft<ResourceDetailVideo>>;
};

export const useResourceAudioDataContextSafe = () => {
  const context = useResourceDataContextSafe();
  if (!isResourceDetailAudio(context.resource)) {
    throw new Error('Not an audio resource');
  }

  return context as ResourceDataContextSafeProps<OptimisticDraft<ResourceDetailAudio>>;
};

export const useResourceDocumentDataContextSafe = () => {
  const context = useResourceDataContextSafe();
  if (!isResourceDetailDocument(context.resource)) {
    throw new Error('Not an document (PDF) resource');
  }

  return context as ResourceDataContextSafeProps<OptimisticDraft<ResourceDetailDocument>>;
};

export const useResourceDefaultDataContextSafe = () => {
  const context = useResourceDataContextSafe();
  if (!isResourceDetailDefault(context.resource)) {
    throw new Error('Not a file resource');
  }

  return context as ResourceDataContextSafeProps<OptimisticDraft<ResourceDetailDefault>>;
};

export const useResourceNotepadDataContextSafe = () => {
  const context = useResourceDataContextSafe();
  if (!isResourceDetailNotepad(context.resource)) {
    throw new Error('Not a notepad resource');
  }

  return context as ResourceDataContextSafeProps<OptimisticDraft<ResourceDetailNotepad>>;
};

export const useResourceFolderDataContextSafe = () => {
  const context = useResourceDataContextSafe();
  if (!isResourceDetailFolder(context.resource)) {
    throw new Error('Not a folder resource');
  }

  return context as ResourceDataContextSafeProps<OptimisticDraft<ResourceDetailFolder>>;
};

export const useResourceImageBookmarkDataContextSafe = () => {
  const context = useResourceDataContextSafe();
  if (!isResourceDetailImageBookmark(context.resource)) {
    throw new Error('Not an image bookmark resource');
  }

  return context as ResourceDataContextSafeProps<OptimisticDraft<ResourceDetailImage>>;
};
