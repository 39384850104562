'use client';

import { Directory, Filesystem } from '@capacitor/filesystem';
import { toast } from '../store/alerts';

import { ResourceDetail } from '@/src/modules/resources/resources.types';
import { isTruthy } from '@/src/utils/guards';
import { Share } from '@capacitor/share';
import inNextServer from '../utils/next';
import { isInMobile } from './mobile';

export const downloadMobile = async (url: string, name: string, _contentType?: string) => {
  if (inNextServer()) return;

  let path: string | undefined;

  // we will download the file into a downloads folder then use the FileSharer plugin to share it
  try {
    toast({
      content: 'Downloading...',
    });

    // on Android there is a bug that prevents the downloadFile function from recursively creating the directory
    // as such we create it separately first to avoid the bug
    if (isInMobile('android')) {
      try {
        await Filesystem.mkdir({
          path: 'downloads',
          directory: Directory.Cache,
          recursive: true,
        });
      } catch (e) {
        console.error(e);
      }
    }

    const { path: newPath } = await Filesystem.downloadFile({
      url: url,
      path: `downloads/${name}`,
      recursive: true,
      directory: Directory.Cache,
    });

    path = newPath;

    if (!path) throw new Error('Could not download file');

    let pathUri: string = path;
    if (isInMobile('android')) {
      pathUri = `file://${path}`;
    }

    const { activityType } = await Share.share({
      title: name,
      files: [pathUri],
      dialogTitle: 'Downloaded file',
    });

    console.log('Share was successful? ' + activityType);

    // delete the file after sharing
    await Filesystem.deleteFile({
      path,
      directory: Directory.Cache,
    });
  } catch (e) {
    console.error(e);
  } finally {
    if (!path) return;

    try {
      // delete the file after sharing
      await Filesystem.deleteFile({
        path,
        directory: Directory.Cache,
      });
    } catch (e) {
      console.error(e);
    }
  }
};

const downloadWeb = async (url: string, name: string, contentType?: string) => {
  if (inNextServer()) return;

  const link = document.createElement('a');
  const data = await fetch(url).then((r) => r.blob());
  const blob = new Blob([data], { type: contentType ?? 'application/octet-stream' });

  const objectUrl = window.URL.createObjectURL(blob);
  link.href = objectUrl;
  link.download = name;
  document.body.appendChild(link);
  link.click();

  toast({
    content: 'Downloading...',
    id: 'download-started',
  });

  // Clean up the DOM and release the object URL
  setTimeout(() => {
    document.body.removeChild(link);
    window.URL.revokeObjectURL(objectUrl);
  }, 100);
};

export const useDownloadResource = (resource?: ResourceDetail) => {
  return resource?.fileUrl
    ? async () => {
        if (!resource.fileUrl) {
          toast({
            content: 'No file to download',
            id: 'no-file-to-download',
          });
          return;
        }

        const title = [resource.name || 'untitled file', resource.extension]
          .filter(isTruthy)
          .join('.');
        const contentType = resource.mimeType || 'application/octet-stream';

        if (isInMobile()) {
          await downloadMobile(resource.fileUrl, title, contentType);
        } else {
          await downloadWeb(resource.fileUrl, title, contentType);
        }
      }
    : undefined;
};
