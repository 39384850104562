import { containerPreviewCompact } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/cssPreviewContainer';
import { cssTextEllipsis } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import styled from 'styled-components';

export const IconFileName: React.FC<{ title: string; extension?: string | null }> = ({
  title,
  extension,
}) => {
  return (
    <FileNameBadge>
      <Title>{title}</Title>
      {extension && <span>{extension}</span>}
    </FileNameBadge>
  );
};

const Title = styled.span`
  ${cssTextEllipsis};
`;

const FileNameBadge = styled.div`
  position: relative;
  max-width: max(200px, 100%);
  text-align: center;

  background: ${cssVar['color-bg-secondary-button']};
  color: ${cssVar['color-text-tertiary']};
  border-radius: 0.5rem;

  padding: 0.375rem 0.5rem;

  font-weight: 400;
  font-size: 0.75rem;

  width: fit-content;

  display: none;
  ${containerPreviewCompact} {
    display: flex;
    overflow: hidden;
  }
`;
