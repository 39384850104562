import clsx from 'clsx';
import React, { useEffect, useMemo } from 'react';

import useUIStore from '@/src/store/ui';
import { shallow } from 'zustand/shallow';
import FileIcon from '../FileIcon';
import styles from './FileDisplay.module.scss';

const FileDisplay: React.FC<{
  fileExtension: string | null;
  fileName: string;
  hideFileName?: boolean;
  nameWithoutExtension?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
  compact?: boolean;

  scale?: number;
}> = ({
  fileExtension,
  compact,
  fileName,
  children,
  hideFileName,
  onClick,
  scale = 1,
  nameWithoutExtension = false,
}) => {
  // make sure name contains the extension
  const nameWithExtension =
    fileName.includes(fileExtension ?? '') || nameWithoutExtension
      ? fileName
      : `${fileName}.${fileExtension}`;

  const zoomLevel = useUIStore((state) => state.zoomLevel, shallow);
  const [forceRefresh, setForceRefresh] = React.useState(1);
  useEffect(() => {
    // if in a retina display, force refresh the file icon
    // this is a hack as the file icon shows up with a random text offset
    // and refreshing the icon fixes it
    if (window.devicePixelRatio > 1) {
      setForceRefresh((prev) => prev + 0.001);
    }
  }, []);

  // also force refresh if the zoom level changes
  useEffect(() => {
    setForceRefresh((prev) => prev + 0.001);
  }, [zoomLevel]);

  const fileIconSize = useMemo(() => {
    if (compact)
      return {
        width: 58 * scale * forceRefresh,
        height: 66 * scale * forceRefresh,
      };

    return {
      width: 202 * scale * forceRefresh,
      height: 213 * scale * forceRefresh,
    };
  }, [compact, scale, forceRefresh]);

  const filenamePadding = useMemo(() => {
    if (scale < 1) return '6px 8px';
    if (compact) return undefined;
    return 10;
  }, [scale, compact]);

  return (
    <div
      className={clsx(
        styles.file,
        scale < 1 && styles.scaled_down,
        onClick && styles.clickable,
        compact && styles.compact,
      )}
      onClick={onClick}
    >
      <div className={styles.file_icon}>
        <FileIcon
          width={fileIconSize.width}
          height={fileIconSize.height}
          extension={fileExtension?.replace('.', '') ?? '?'}
        />

        {children}
      </div>
      {!hideFileName && (
        <div
          className={styles.filename}
          style={{
            padding: filenamePadding,
          }}
        >
          <span>{nameWithExtension}</span>
        </div>
      )}
    </div>
  );
};

export default FileDisplay;
