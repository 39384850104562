import { useResourceDataContextSafe } from '@/src/modules/resource-detail/components/context/resourceDataContext';
import { cssVar } from '@/src/modules/ui/theme/variables';
import styled from 'styled-components';

type AbsPosition = 'inside-top-left';
export const ColorLabel: React.FC<{
  absPosition?: 'inside-top-left';
}> = ({ absPosition }) => {
  const { resource, parentColorLabels } = useResourceDataContextSafe();

  if (!parentColorLabels || !resource.label) return null;

  const color = parentColorLabels.find((item) => item.id === resource.label?.id)?.hexColor;

  if (!color) {
    return null;
  }

  return <Label absPosition={absPosition} style={{ background: color }} />;
};

const Label = styled.div<{ absPosition?: AbsPosition }>`
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  position: absolute;
  left: -1.5rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  outline: 2px solid ${cssVar['color-bg-primary']};

  ${(p) =>
    p.absPosition === 'inside-top-left' &&
    `
    top: .875rem;
    right: .6125rem;
    left: auto;
    bottom: auto;
  `}
`;
