import { cssVar } from '@/src/modules/ui/theme/variables';
import { css, keyframes } from 'styled-components';

export const inAnimationMobileDrawer = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const outAnimationMobileDrawer = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`;

export const cssRadixPopoverContentOverride = css`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  will-change: transform;
  max-height: calc(100% - env(safe-area-inset-top, 0px) - 1rem);
  transform: translateY(100%);
  opacity: 0;

  &[data-state='open'] {
    animation: ${inAnimationMobileDrawer} 0.2s ease-out forwards !important;
    /** adding delay for the keyboard to hide */
    animation-delay: 0.25s !important;
  }

  &[data-state='closed'] {
    animation: ${outAnimationMobileDrawer} 0.15s ease-out forwards !important;
  }
  // style parent
  div:has(> &) {
    z-index: 126 !important;
    transform: none !important;
    height: 100%;
    width: 100%;
    pointer-events: auto !important;
    /**
    there is no overlay primitive for dropdown... And we can't use other primitives due to context usage
    so we need to hack it this way
    */
    &:before {
      position: fixed;
      content: '';
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${cssVar['color-bg-overlay']};
      will-change: opacity;
      top: 0;
    }
  }
`;
