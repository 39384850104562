import { CommentWithAuthor } from '@/src/modules/comments/comments.types';
import { cssVar } from '@/src/modules/ui/theme/variables';
import AvatarUser from '@/src/modules/user/components/AvatarUser/AvatarUser';
import styled from 'styled-components';

export const ResourcePreviewListItemComment: React.FC<{
  comment: CommentWithAuthor;
}> = ({ comment }) => {
  return (
    <Wrapper>
      <AvatarUser user={comment.user} size={16} />
      <Text>{comment.content}</Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: 100%;
  background: rgba(${cssVar['color-bg-primary-reverse-rgb']}, 0.02);
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
`;

const Text = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-grow: 1;
  font-size: 0.75rem;
  color: ${cssVar['color-text-primary']};
  opacity: 0.6;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
