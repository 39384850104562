import { Editor } from '@tiptap/react';
import DOMPurify from 'dompurify';
import { TIPTAP_EDITOR_SCHEMA_VERSION, TiptapDataValue } from './types';

export const tiptapSanitizeContent = (content: string) => {
  return DOMPurify.sanitize(content, {
    ALLOWED_TAGS: [
      'em',
      'strong',
      'a',
      'code',
      's',
      'i',
      'b',
      'u',
      'mark',
      'br',
      'a',
      'p',
      'ol',
      'ul',
      'li',
      'blockquote',
      'pre',
      'code',
      'img',
      'table',
      'tr',
      'td',
      'th',
      'tbody',
      'thead',
      'tfoot',
      'caption',
      'div',
      'span',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'input',
    ],
    ALLOWED_ATTR: [
      'href',
      'target',
      'rel',
      'class',
      'src',
      'alt',
      'width',
      'data-uuid',
      'data-created-at',
      'data-checked',
      'data-type',
      'data-role',
      'type',
      'checked',
    ],
  });
};

const previewUnwantedAttributes = ['contenteditable', 'data-uuid', 'data-created-at'];

/**
 * This will get the HTML content of the editor and return a preview of it.
 * @param content The HTML content of the editor
 * @param maxCharacters The maximum number of characters to return
 * @returns The preview of the content
 */
export const generateContentPreview = (content: string, _maxCharacters: false | number = 200) => {
  // since we add the ellipsis symbol '…' to the end of the preview, we need to subtract 1 from the maxCharacters
  const maxCharacters = _maxCharacters === false ? 0 : _maxCharacters - 1;

  const parser = new DOMParser();
  const doc = parser.parseFromString(tiptapSanitizeContent(content), 'text/html');
  const body = doc.body;
  let newHtml = '';
  let charCount = 0;

  const appendText = (text: string) => {
    const sliceAmount =
      maxCharacters === 0 ? text.length : Math.min(maxCharacters - charCount, text.length);

    if (sliceAmount <= 0 && maxCharacters > 0) {
      return;
    }

    newHtml += text.slice(0, sliceAmount) + (sliceAmount < text.length ? '…' : '');
    charCount += sliceAmount;
  };

  const traverse = (node: Node) => {
    if (charCount >= maxCharacters && maxCharacters > 0) {
      return;
    }

    if (node.nodeType === Node.TEXT_NODE) {
      appendText(node.textContent || '');
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node as HTMLElement;
      newHtml += `<${element.tagName.toLowerCase()}`;
      for (const attr of element.attributes) {
        if (previewUnwantedAttributes.includes(attr.name)) {
          continue;
        }

        newHtml += ` ${attr.name}="${attr.value}"`;
      }
      newHtml += '>';

      for (const child of element.childNodes) {
        traverse(child);
      }

      newHtml += `</${element.tagName.toLowerCase()}>`;
    }
  };

  for (const child of body.childNodes) {
    traverse(child);
  }

  return newHtml;
};

export const getTiptapDataValue = (editor: Editor, oldValue?: TiptapDataValue) => {
  return {
    version: TIPTAP_EDITOR_SCHEMA_VERSION,
    content: editor.getHTML(),
    lastUpdated: Date.now(),
    time: Date.now(),
    blocks: [
      {
        type: 'paragraph',
        data: {
          text: editor.storage.markdown.getMarkdown(),
        },
      },
    ],
    // Keep old data for backwards compatibility
    data: oldValue?.data,
  };
};
