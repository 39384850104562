import styled from 'styled-components';

import FolderIcon from '@/public/images/icons/Folder.png';
import FolderDeviceIcon from '@/public/images/icons/FolderDevice.png';
import { isDeviceConnectionType } from '@/src/modules/connections/connections.utils';
import { useResourceDataContextSafe } from '@/src/modules/resource-detail/components/context/resourceDataContext';

export const ResourcePreviewListItemFolder: React.FC = () => {
  const { resource } = useResourceDataContextSafe();
  return isDeviceConnectionType(resource.root?.subtype) ? (
    <Image src={FolderDeviceIcon.src} alt="Device Folder" draggable={false} />
  ) : (
    <Image src={FolderIcon.src} alt="Folder" draggable={false} />
  );
};

/** thumbnail or empty state */

const Image = styled.img`
  max-height: 2rem;
  max-width: 2rem;
  height: auto;
  width: auto;
`;
