import TagFilled from '@/public/images/icons/TagFilled.svg';
import { useAuthIsLoggedIn } from '@/src/hooks/auth';
import { cn } from '@/src/lib/utils';
import { ButtonIcon } from '@/src/modules/ui/components/Button';
import { mediaHover } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { PrivateTag } from '@fabric/woody-client';
import styled from 'styled-components';
import EditTagsModal from '../EditTagsModal';
import { ModalControls } from '@/src/modules/ui/components/Modal';

export const TagButtonBase = styled(ButtonIcon)`
  position: absolute;
  bottom: 20px;
  left: 1rem;
  z-index: 10;
  // adding shadow because when it's on top of element with same bg, e.g. link has description, it's blending in
  box-shadow: 0px 1px 5px rgba(128, 128, 128, 0.15);
  background: ${cssVar['color-bg-primary']};
  color: ${cssVar['color-text-primary']};
  ${mediaHover} {
    &:hover {
      background: ${cssVar['color-bg-secondary']};
    }
  }
`;

type TagButtonProps = {
  className?: string;
  selectedTags?: PrivateTag[];
  onSelect: (tag: PrivateTag, selected: boolean) => void;
  style?: React.CSSProperties;
} & Partial<ModalControls>;

const TagButton: React.FC<TagButtonProps> = ({
  selectedTags,
  onSelect,
  style,
  className,
  open,
  onOpenChange,
}) => {
  const isLoggedIn = useAuthIsLoggedIn();
  if (!isLoggedIn) return null;

  return (
    <EditTagsModal
      selectedTags={selectedTags}
      onSelect={onSelect}
      open={open}
      onOpenChange={onOpenChange}
    >
      <TagButtonBase variant="bg-secondary" className={className} style={style}>
        <TagFilled className={cn((selectedTags?.length ?? 0) > 0 && 'text-primary')} />
      </TagButtonBase>
    </EditTagsModal>
  );
};

export default TagButton;
