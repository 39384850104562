import { createLogger } from '@/src/lib/logger/createLogger';
import { useResourceDataContextSafe } from '@/src/modules/resource-detail/components/context/resourceDataContext';
import { ResourcePreviewFolder } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/Content/ResourcePreviewFolder';
import { ResourcePreviewHighlight } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/Content/ResourcePreviewHighlight';
import { ResourcePreviewImageBookmark } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/Content/ResourcePreviewImageBookmark';
import { ResourcePreviewNotepad } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/Content/ResourcePreviewNotepad';
import { ResourcePreviewPage } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/Content/ResourcePreviewPage';
import { ResourcePreviewFile } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/Content/ResourcePreviewStoredFile';
import {
  isResourceDetailBookmark,
  isResourceDetailFolder,
  isResourceDetailHighlight,
  isResourceDetailImageBookmark,
  isResourceDetailNotepad,
} from '@/src/modules/resources/utils/resourceDetailGuards';

const logger = createLogger('ResourcePreviewContent');

export const ResourcePreviewContent: React.FC = () => {
  const { resource } = useResourceDataContextSafe();

  /**
   * must preceed ResourcePreviewFile because its a subset of kind.image
   */
  if (isResourceDetailImageBookmark(resource)) {
    return <ResourcePreviewImageBookmark />;
  }
  if (isResourceDetailBookmark(resource)) {
    return <ResourcePreviewPage />;
  }

  if (isResourceDetailHighlight(resource)) {
    return <ResourcePreviewHighlight />;
  }

  if (isResourceDetailFolder(resource)) {
    return <ResourcePreviewFolder />;
  }

  if (isResourceDetailNotepad(resource)) {
    return <ResourcePreviewNotepad />;
  }

  if (resource.fileUrl) {
    return <ResourcePreviewFile />;
  }

  logger.error('Unhandled render', resource);

  return null;
};
