import { OptimisticDraft } from '@/src/types/draftable';
import { components, PrivateTag } from '@fabric/woody-client';

type SuggestionContext = components['schemas']['SuggestionContext'];
type SuggestedCategories = components['schemas']['SuggestedCategories'];

enum SuggestionItemType {
  TAG = 'tag',
  FOLDER = 'folder',
}

interface SuggestionItem {
  type: SuggestionItemType;
  name: string;
  id?: string;

  // Only for tags
  tag?: OptimisticDraft<PrivateTag>;
}

type SelectedSuggestionItem = {
  id: string | Promise<string>;
  type: SuggestionItemType;
  name: string;

  // Only for tags
  tag?: PrivateTag | Promise<PrivateTag>;
};

export type { SuggestionContext, SuggestionItem, SuggestedCategories, SelectedSuggestionItem };
export { SuggestionItemType };
