import React, { Fragment, ReactNode } from 'react';
import styles from './Toolbar.module.scss';

import { useDomContentRect } from '@/src/hooks/useDomRect';
import { mediaMobile } from '@/src/modules/ui/styled-utils';
import { Editor } from '@tiptap/react';
import clsx from 'clsx';
import { createGlobalStyle } from 'styled-components';
import {
  ToolbarButtonComponent,
  ToolbarDropdownComponent,
  ToolbarToggleComponent,
} from './ToolbarItems';
import { ToolbarGroup, ToolbarItem, ToolbarItemType } from './types';

const ToolbarGlobalStyles = createGlobalStyle<{ toolbarHeight?: number }>`
  ${mediaMobile} {
    // we render the offset on mobile only because otherwise the toolbar is being displayed on the top
    :root {
      --toolbar-height: ${(p) => p.toolbarHeight || 0}px;
    }
  }
`;

const preventEditorLoseFocus = (editor: Editor) => () => {
  if (!editor.isFocused) return;

  editor.chain().focus();
};

const Toolbar: React.FC<{
  editor: Editor;
  groups: ToolbarGroup[];
  floating?: boolean;
  onToolbarHeightChange?: (height: number) => void;
  noSeperator?: boolean;
}> = ({ editor, groups, floating, onToolbarHeightChange, noSeperator = false }) => {
  const toolbarRef = React.useRef<HTMLDivElement>(null);
  const [toolbarRect] = useDomContentRect(toolbarRef.current);

  React.useEffect(() => {
    onToolbarHeightChange?.(toolbarRect?.height || 0);
  }, [toolbarRect?.height, onToolbarHeightChange]);

  const mappedGroups = groups.map(({ name, label, items }, index) => {
    return (
      <Fragment key={`fragment-${name}`}>
        <div aria-label={label} key={name} role="group" className={styles.ToolbarGroup}>
          {editor && renderToolbarGroupItems(editor, items)}
        </div>
        {index < groups.length - 1 && !noSeperator && (
          <div key={`separator-${name}`} className={styles.ToolbarSeparator} role="separator" />
        )}
      </Fragment>
    );
  });

  return (
    <div
      className={clsx(
        styles.ToolbarRoot,
        floating && styles.ToolbarFloating,
        'dashboard_scrollbar',
      )}
      role="toolbar"
      draggable={false}
      onTouchStart={preventEditorLoseFocus(editor)}
      onTouchEnd={preventEditorLoseFocus(editor)}
      onClick={preventEditorLoseFocus(editor)}
      data-tiptap-toolbar
      data-tiptap-fixed-toolbar
      ref={toolbarRef}
    >
      <ToolbarGlobalStyles toolbarHeight={toolbarRect?.height} />
      {mappedGroups}
    </div>
  );
};

const renderToolbarGroupItems = (editor: Editor, items: ToolbarItem[]): ReactNode[] => {
  return items.map((item) => {
    switch (item.type) {
      case ToolbarItemType.Toggle:
        return <ToolbarToggleComponent key={item.key} editor={editor} item={item} />;
      case ToolbarItemType.Dropdown:
        return <ToolbarDropdownComponent key={item.key} editor={editor} item={item} />;
      case ToolbarItemType.Button:
        return <ToolbarButtonComponent key={item.key} editor={editor} item={item} />;
      case ToolbarItemType.Custom:
        return item.render(item.key, editor, false);
    }
  });
};

export default Toolbar;
