import { cn } from '@/src/lib/utils';
import { IconPencil, IconTrash } from '@/src/modules/icons';
import { useMutationTagDelete } from '@/src/modules/tags/mutations/useMutationTagDelete';
import { DropdownMenu } from '@/src/modules/ui/components/DropdownMenu/DropdownMenu';
import { Flex } from '@/src/modules/ui/components/Flex';
import { Label } from '@/src/modules/ui/components/Label';
import { P } from '@/src/modules/ui/components/Typography';
import { mediaHover } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { Checkbox } from '@/src/ui/Checkbox/Checkbox';
import { PrivateTag } from '@fabric/woody-client';
import Link from 'next/link';
import React, { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';
import CreateOrRenameTagModal from './CreateOrRenameTagModal';
import ShockIcon from '@/public/images/icons/Shock.svg';
import { PrivateTagListItem } from './EditTagsModal';

interface Props {
  tag: PrivateTagListItem;

  onSelect?: (tag: PrivateTagListItem, selected: boolean) => void;
}

const TagListItemWrapper = styled(Flex).attrs({
  gap: 'elementsContainer',
  alignItems: 'center',
})`
  position: relative;
  height: 3.375rem;
  padding: 0 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  background-color: ${cssVar['color-bg-primary']};
  ${mediaHover} {
    &:hover {
      background-color: ${cssVar['color-bg-tertiary']};
      cursor: pointer;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${cssVar['color-border-primary']};
  }
`;

const TagItem: React.FC<Props> = ({ tag, onSelect }) => {
  return (
    <TagListItemWrapper className={cn('group')}>
      {Boolean(!onSelect && !tag.checked) && (
        <Link href={`/tags/${tag.id}`} className="absolute inset-0 " />
      )}

      <TagItemContent>
        {(onSelect || tag.checked !== undefined) && (
          <>
            <Checkbox
              id={`tag-${tag.id}-select`}
              name={`tag-${tag.id}-select`}
              aria-label={`Select tag ${tag.name}`}
              checked={tag.checked}
              onCheckedChange={() => {
                /**
                 * when indeterminate, it means that some of the tags are selected
                 * default behaviour for checkbox is when indeterminate is set, on change it's checked
                 * we want reverse https://www.figma.com/file/ohWxmYJ78RRcXkcGqrhou5/product-dev-ASYNC?type=design&node-id=26744-37571&mode=design&t=kHWTxcYcDeLEqR9z-4
                 */
                onSelect?.(tag, !tag.checked ? true : false);
              }}
              className={cn(
                'transition-colors duration-200 ease-in-out',
                !tag.checked &&
                  'border-foreground-300 group-hover:border-primary group-hover:bg-primary group-hover:bg-opacity-[0.04]',
              )}
            />
            <Label htmlFor={`tag-${tag.id}-select`} className="absolute inset-0 cursor-pointer">
              <span className="sr-only">Select tag {tag.name}</span>
            </Label>
          </>
        )}
        <TagItemName>{tag.name}</TagItemName>
      </TagItemContent>

      {tag.suggested && (
        <ShockIcon
          style={{
            color: cssVar['color-text-quaternary'],
          }}
        />
      )}

      <TagItemDropdown tag={tag} />
    </TagListItemWrapper>
  );
};

const TagItemContent = React.forwardRef<HTMLDivElement, ComponentPropsWithoutRef<'div'>>(
  ({ className, ...props }, ref) => {
    return (
      <div ref={ref} className={cn('flex flex-grow gap-2.5 items-center', className)} {...props} />
    );
  },
);
TagItemContent.displayName = 'TagItemContent';

type TagItemDropdownProps = {
  tag: PrivateTag;
};

const TagItemDropdown: React.FC<TagItemDropdownProps> = ({ tag }) => {
  const deleteTagMutation = useMutationTagDelete();

  const [renameOpen, setRenameOpen] = React.useState(false);

  return (
    <>
      <CreateOrRenameTagModal tag={tag} open={renameOpen} setOpen={setRenameOpen} noTrigger />

      <DropdownMenu
        renderTriggerElement={({ onOpenChange }) => (
          <DropdownMenu.TriggerButtonThreeDotsSlim onOpenChange={onOpenChange} />
        )}
      >
        <DropdownMenu.Group>
          <DropdownMenu.Item iconElement={<IconPencil />} onClick={() => setRenameOpen(true)}>
            Edit name
          </DropdownMenu.Item>
        </DropdownMenu.Group>
        <DropdownMenu.Group>
          <DropdownMenu.Item
            variant="danger"
            iconElement={<IconTrash />}
            onClick={() => {
              deleteTagMutation.confirmAndMutate(tag.id);
            }}
          >
            Delete
          </DropdownMenu.Item>
        </DropdownMenu.Group>
      </DropdownMenu>
    </>
  );
};

const TagItemName = styled(P).attrs((props) => {
  return {
    color: 'secondary',
    ...props,
  };
})``;

export default TagItem;
