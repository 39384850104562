import { ResourceDataContextProvider } from '@/src/modules/resource-detail/components/context/ResourceDataContextProvider';
import { ResourcePreviewComponentV2 } from '@/src/modules/resources/components/ResourcePreview/ResourcePreview.types';
import { ResourcePreviewGridItem } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/ResourcePreviewGridItem';
import { ResourcePreviewListItem } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewList/ResourcePreviewListItem';

export const ResourcePreview: ResourcePreviewComponentV2 = ({
  draggableRef,
  onMouseDown,
  resource,
  ...rest
}) => {
  return (
    <ResourceDataContextProvider resource={resource} parentColorLabels={rest.colorLabels}>
      {rest.variant === 'list' ? (
        <ResourcePreviewListItem {...rest} />
      ) : (
        <ResourcePreviewGridItem {...rest} draggableRef={draggableRef} onMouseDown={onMouseDown} />
      )}
    </ResourceDataContextProvider>
  );
};
