/**
 * A dictionary mapping MIME types to human-readable file type descriptions.
 */
const mimeToKind: Record<string, string> = {
  'application/pdf': 'PDF Document',
  'application/msword': 'Microsoft Word Document',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'Microsoft Word Document (DOCX)',
  'application/vnd.ms-excel': 'Microsoft Excel Spreadsheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    'Microsoft Excel Spreadsheet (XLSX)',
  'application/vnd.ms-powerpoint': 'Microsoft PowerPoint Presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    'Microsoft PowerPoint Presentation (PPTX)',
  'application/zip': 'ZIP Archive',
  'application/x-rar-compressed': 'RAR Archive',
  'application/x-7z-compressed': '7-Zip Archive',
  'application/json': 'JSON File',
  'application/xml': 'XML File',
  'application/javascript': 'JavaScript File',
  'application/octet-stream': 'Binary File',
  'text/plain': 'Plain Text File',
  'text/html': 'HTML File',
  'text/css': 'CSS File',
  'text/csv': 'CSV File',
  'text/markdown': 'Markdown File',
  'image/jpeg': 'JPEG Image',
  'image/png': 'PNG Image',
  'image/gif': 'GIF Image',
  'image/svg+xml': 'SVG Image',
  'image/webp': 'WebP Image',
  'image/tiff': 'TIFF Image',
  'image/bmp': 'BMP Image',
  'audio/mpeg': 'MP3 Audio',
  'audio/wav': 'WAV Audio',
  'audio/ogg': 'OGG Audio',
  'audio/midi': 'MIDI Audio',
  'audio/webm': 'WebM Audio',
  'video/mp4': 'MP4 Video',
  'video/mpeg': 'MPEG Video',
  'video/quicktime': 'QuickTime Video',
  'video/webm': 'WebM Video',
  'video/x-msvideo': 'AVI Video',
  'application/vnd.android.package-archive': 'Android Package',
  'application/vnd.apple.installer+xml': 'Apple Installer Package',
  'application/x-shockwave-flash': 'Adobe Flash',
  'application/vnd.visio': 'Microsoft Visio Document',
  'application/rtf': 'Rich Text Format',
  'application/x-font-ttf': 'TrueType Font',
  'application/x-font-otf': 'OpenType Font',
  'application/vnd.ms-fontobject': 'Embedded OpenType Font',
  'application/x-bittorrent': 'BitTorrent File',
  'application/x-tex': 'TeX Document',
  'application/graphql': 'GraphQL',
  'application/sql': 'SQL File',
  'application/x-httpd-php': 'PHP Script',
  'application/x-python-code': 'Python Script',
  'application/vnd.apple.keynote': 'Apple Keynote Presentation',
  'application/vnd.apple.pages': 'Apple Pages Document',
  'application/vnd.apple.numbers': 'Apple Numbers Spreadsheet',
  'application/vnd.oasis.opendocument.text': 'OpenDocument Text',
  'application/vnd.oasis.opendocument.spreadsheet': 'OpenDocument Spreadsheet',
  'application/vnd.oasis.opendocument.presentation': 'OpenDocument Presentation',
  'application/epub+zip': 'EPUB E-book',
  'application/x-photoshop': 'Adobe Photoshop Document',
  'application/postscript': 'PostScript File',
  'application/x-font-woff': 'WOFF Font',
  'application/x-font-woff2': 'WOFF2 Font',
  'application/x-yaml': 'YAML File',
  'application/x-sh': 'Shell Script',
  'application/x-binary': 'Binary Executable',
  'text/xml': 'XML Text',
  'text/rtf': 'Rich Text Format',
  'image/x-icon': 'ICO Image',
  'image/vnd.adobe.photoshop': 'Photoshop Document',
  'audio/aac': 'AAC Audio',
  'video/x-matroska': 'Matroska Video',
  'application/vnd.fabric.notepad': 'Notepad',
  'application/vnd.fabric.highlight': 'Highlight',
  'application/vnd.fabric.bookmark': 'Bookmark',
};

/**
 * Generates a human-readable description of a file type based on its MIME type and file extension.
 *
 * @param mime - The MIME type of the file (e.g., 'application/pdf', 'image/jpeg').
 * @param extension - The file extension, with or without the leading dot (e.g., '.txt', 'jpg').
 * @returns A string describing the file type.
 *
 * If the MIME type is found in the predefined mimeToKind dictionary, it returns the corresponding description.
 * Otherwise, it constructs a description using the file extension and the general MIME type category.
 *
 * @example
 * generateKind('application/pdf', '.pdf') // returns 'PDF Document'
 * generateKind('image/png', 'png') // returns 'PNG Image'
 * generateKind('application/x-custom', '.cst') // returns 'CST application'
 */
const generateKind = (mime: string, extension: string): string => {
  if (mime in mimeToKind) {
    return mimeToKind[mime];
  }

  // Remove the dot from the extension if present
  const cleanExtension = extension.startsWith('.') ? extension.slice(1) : extension;

  // Get the first part of the MIME type (before the '/')
  const mimeType = mime.split('/')[0];

  // Capitalize the extension and combine with the MIME type
  return `${cleanExtension.toUpperCase()} ${mimeType}`;
};

function base64ToUint8Array(base64String: string, maxBytes?: number): Uint8Array {
  // Remove the data URL prefix if present (although usually not the case)
  const base64Data = base64String.replace(/^data:[^;]+;base64,/, '');

  // Calculate the number of bytes to process
  const bytesToProcess = maxBytes
    ? Math.min(Math.ceil((maxBytes * 4) / 3), base64Data.length)
    : base64Data.length;

  const processedBase64 = base64Data.slice(0, bytesToProcess);

  const binaryString = atob(processedBase64);
  const len = Math.min(binaryString.length, maxBytes || Infinity);
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes;
}

export { generateKind, base64ToUint8Array };
