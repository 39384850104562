import { useAuthUser } from '@/src/hooks/auth';
import { useDownloadResource } from '@/src/hooks/useDownloadFdoc';
import {
  ResourceDataContext,
  ResourceDataContextProps,
} from '@/src/modules/resource-detail/components/context/resourceDataContext';
import { ResourceDetail } from '@/src/modules/resources/resources.types';
import { getResourceNamePlaceholder } from '@/src/modules/resources/utils/getResourceTitle';
import {
  isResourceDetailAudio,
  isResourceDetailDocument,
  isResourceDetailFolder,
  isResourceDetailImage,
  isResourceDetailNotepad,
  isResourceDetailVideo,
} from '@/src/modules/resources/utils/resourceDetailGuards';
import { OptimisticDraft } from '@/src/types/draftable';
import { isTruthy } from '@/src/utils/guards';
import React from 'react';

interface ResourceDataContextProviderProps {
  children: React.ReactNode;
  resource?: OptimisticDraft<ResourceDetail>;
  parentColorLabels?: ResourceDataContextProps['parentColorLabels'];
}

export const ResourceDataContextProvider: React.FC<ResourceDataContextProviderProps> = ({
  resource,
  children,
  parentColorLabels,
}) => {
  const user = useAuthUser();
  const handleDownload = useDownloadResource(resource);

  const providedValue: ResourceDataContextProps = React.useMemo(() => {
    return {
      resource,
      readOnly: !resource
        ? true
        : resource.root?.type === 'INTEGRATION' || resource.user.id !== user?.id,
      handleDownload,
      fullName: [resource?.name, resource?.extension].filter(isTruthy).join('.'),
      placeholderName: resource ? getResourceNamePlaceholder(resource) : 'Untitled',
      parentColorLabels,
      isRenameable: resource
        ? isResourceDetailAudio(resource) ||
          isResourceDetailAudio(resource) ||
          isResourceDetailDocument(resource) ||
          isResourceDetailFolder(resource) ||
          isResourceDetailVideo(resource) ||
          isResourceDetailNotepad(resource) ||
          isResourceDetailImage(resource)
        : false,
    };
  }, [resource, user?.id, handleDownload, parentColorLabels]);

  return (
    <ResourceDataContext.Provider value={providedValue}>{children}</ResourceDataContext.Provider>
  );
};
