import { CommentWithAuthor } from '@/src/modules/comments/comments.types';
import { containerPreviewCompact } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/cssPreviewContainer';
import { hasHover } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import AvatarUser from '@/src/modules/user/components/AvatarUser/AvatarUser';
import plur from 'plur';
import styled from 'styled-components';

export const ResourcePreviewPinnedComment: React.FC<{
  comment: CommentWithAuthor;
  commentsCount: number;
}> = ({ comment, commentsCount }) => {
  return (
    <Wrapper>
      <AvatarUser user={comment.user} size={16} />
      <Text>{comment.content}</Text>
      {commentsCount > 0 && (
        <TextSmallScreen>
          {commentsCount} {plur('comment', commentsCount)}
        </TextSmallScreen>
      )}
    </Wrapper>
  );
};

const Text = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-grow: 1;
  font-size: 0.75rem;
  color: ${cssVar['color-text-quaternary']};
  word-break: break-word;
  hyphens: auto;
  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  ${containerPreviewCompact} {
    display: none;
  }
`;
const Wrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: 100%;
  ${hasHover} {
    ${Text}:hover {
      color: ${cssVar['color-text-secondary']};
    }
  }
`;

const TextSmallScreen = styled(Text)`
  display: none;
  ${containerPreviewCompact} {
    display: block;
    color: ${cssVar['color-text-tertiary']};
  }
`;
