import { ResourceRoot } from '@/src/modules/resource-roots/resource-roots.types';
import {
  LocationSelectorNodeData,
  LocationSelectorNodePathItem,
} from '@/src/modules/resources/components/DestinationSelector/types';
import { ResourceDetailFolder } from '@/src/modules/resources/resources.types';
import { isResourceIntegrationItem } from '@/src/modules/resources/utils/resourceDetailGuards';
import { Ancestor } from '@/src/types/api';

export const folderToLocationSelectorNodeData = (
  folder: ResourceDetailFolder,
): LocationSelectorNodeData => {
  return {
    id: folder.id,
    title: folder.name || 'Untitled',
    displayType: 'folder',
    isRootIntegration: isResourceIntegrationItem(folder),
  };
};
export const rootToLocationSelectorNodeData = (root: ResourceRoot): LocationSelectorNodeData => {
  return {
    id: root.id,
    title: root.folder.name || 'Untitled',
    displayType: root.subtype === 'inbox' ? ('inbox' as const) : ('space' as const),
    isRootIntegration: root.type === 'INTEGRATION',
  };
};

export const ancestorToLocationSelectorNodePathItem = (
  ancestor: Ancestor,
): LocationSelectorNodePathItem => {
  return {
    id: ancestor.id,
    title: ancestor.name,
  };
};
