import { RESOURCE_GRID_PREVIEW_CONTENT } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/constants';
import { containerPreviewCompact } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/cssPreviewContainer';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { css } from 'styled-components';

export const cssPreviewShadow = css`
  box-shadow:
    0px 4px 6px rgba(0, 0, 0, 0.02),
    0px 0px 2px rgba(0, 0, 0, 0.05);
`;

export const cssAspectRatio = css`
  aspect-ratio: ${RESOURCE_GRID_PREVIEW_CONTENT.width} / ${RESOURCE_GRID_PREVIEW_CONTENT.height};
  ${containerPreviewCompact} {
    aspect-ratio: initial;
  }
`;

export const cssHover = css`
  @media (hover: hover) {
    & {
      position: relative;
    }
    &:before {
      content: '';
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: ${cssVar['resource-hover-overlay-color']};
      z-index: 100;
      pointer-events: none;
      transition: 0.1s;
      opacity: 0;
      border-radius: 1rem;
    }
    &:hover:before {
      opacity: 1;
    }
    &:hover {
      ${containerPreviewCompact} {
        [data-preview-content-wrapper='storef_file_thumbnail'] {
          opacity: 0.8;
        }
      }
    }
  }
`;
