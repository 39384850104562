import EnterIcon from '@/public/images/icons/Enter.svg';
import NewTagIcon from '@/public/images/icons/NewTag.svg';
import { Button } from '@/src/modules/ui/components/Button';
import { Kbd } from '@/src/modules/ui/components/Kbd';
import { cssVar } from '@/src/modules/ui/theme/variables';
import React from 'react';
import styled from 'styled-components';
import TagItem from './TagItem';
import { PrivateTagListItem } from './EditTagsModal';

interface Props {
  tags?: PrivateTagListItem[];
  isLoading?: boolean;
  searchQuery?: string;
  onSelect?: (tag: PrivateTagListItem, selected: boolean) => void;
}

const ListContainer = styled.div`
  border: 1px solid ${cssVar['color-border-primary']};
  border-radius: 4px;
  width: 100%;

  box-shadow:
    0px 4px 25px 0px #0000000d,
    0px 4px 4px 0px #00000005;
  overflow: hidden;
`;

const TagsList: React.FC<Props> = ({ tags, isLoading, searchQuery, onSelect }) => {
  const isSearchExactAsAnyTag = tags?.some((tag) => tag.name === searchQuery?.toLowerCase());

  return (
    <>
      {!isLoading && !isSearchExactAsAnyTag && searchQuery && (
        <Button variant="icon" type="submit" size="lg" style={{ width: '100%' }}>
          <NewTagIcon className="w-5 h-[22px]" />
          <span>
            Create tag <strong className="font-bold">&ldquo;{searchQuery}&rdquo;</strong>
          </span>
          <Kbd className="ml-auto">
            <EnterIcon />
          </Kbd>
        </Button>
      )}
      {Boolean(tags?.length) && (
        <ListContainer>
          {tags!.map((tag) => (
            <TagItem tag={tag} key={tag.id} onSelect={onSelect} />
          ))}
        </ListContainer>
      )}

      {isLoading && Boolean(!tags?.length) && (
        <div className="text-[15px] text-foreground-600 px-[15px] py-2">Loading...</div>
      )}
      {!isLoading && !tags?.length && !searchQuery && (
        <div className="text-[15px] text-foreground-600 px-[15px] py-2">No tags found</div>
      )}
    </>
  );
};

export default TagsList;
