import { IconBookmark } from '@/src/modules/icons';
import { useResourceDataContextSafe } from '@/src/modules/resource-detail/components/context/resourceDataContext';
import { Image } from '@/src/modules/ui/components/Image';
import { cssVar } from '@/src/modules/ui/theme/variables';
import styled from 'styled-components';

export const ResourcePreviewListItemImage: React.FC = () => {
  const { resource, placeholderName } = useResourceDataContextSafe();
  return resource.thumbnail?.sm ? (
    <ThumbnailImage
      src={resource.thumbnail?.sm}
      alt={resource.name || placeholderName}
      draggable={false}
    />
  ) : (
    <IconBookmark style={{ width: '1rem', height: '1rem' }} />
  );
};

/** thumbnail or empty state */

const ThumbnailImage = styled(Image)`
  width: 100%;
  flex-grow: 1;
  object-fit: cover;
  display: block;
  border-radius: 0.25rem;
  background: ${cssVar['color-bg-section']};
`;
