import { useResourceDataContextSafe } from '@/src/modules/resource-detail/components/context/resourceDataContext';
import { FaviconImage } from '@/src/modules/ui/components/Favicon/FaviconImage';
import { Flex } from '@/src/modules/ui/components/Flex';
import { P, SPAN } from '@/src/modules/ui/components/Typography';
import { cssTextEllipsis } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { cleanUrl } from '@/src/utils/text';
import styled from 'styled-components';

const PageUrl: React.FC<{ url: string }> = ({ url }) => {
  return (
    <Flex alignItems="center" gap="element">
      <StyledFavicon pageUrl={url} />
      <Url>{cleanUrl(url)}</Url>
    </Flex>
  );
};

export const ResourcePreviewListTitle: React.FC = () => {
  const { resource, placeholderName } = useResourceDataContextSafe();
  return (
    <Wrapper direction="column" gap="element">
      <P size="xs" color="tertiary" ellipsis as="div">
        {resource.name || <P color="placeholder">{placeholderName}</P>}
        {resource.extension && <SPAN color="placeholder">.{resource.extension}</SPAN>}
      </P>
      {resource.originUrl && <PageUrl url={resource.originUrl} />}
    </Wrapper>
  );
};

const Wrapper = styled(Flex).attrs({ direction: 'column' })`
  gap: 0.25rem;
`;

const StyledFavicon = styled(FaviconImage)`
  height: 1rem;
  width: 1rem;
  border-radius: 0.125rem;
  padding: 0;
`;

const Url = styled.div`
  ${cssTextEllipsis};
  font-size: 0.75rem;
  color: ${cssVar['color-text-placeholder']};
`;
