import { ResourceDetail } from '@/src/modules/resources/resources.types';
import { PrivateTag } from '@fabric/woody-client';

export const mutateResourceTag = <T extends ResourceDetail>(
  resource: T,
  tag: PrivateTag,
  operation: 'assign' | 'unassign',
) => {
  const tags = resource.tags.slice();
  const tagIndex = tags.findIndex((t) => t.id === tag.id);

  if (operation === 'assign' && tagIndex === -1) {
    tags.push(tag);
  } else if (operation === 'unassign' && tagIndex !== -1) {
    tags.splice(tagIndex, 1);
  }

  return {
    ...resource,
    tags,
  };
};
