import { createLogger } from '@/src/lib/logger/createLogger';
import { useResourceDataContextSafe } from '@/src/modules/resource-detail/components/context/resourceDataContext';
import { ResourcePreviewListItemFolder } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewList/preview/ResourcePreviewListItemFolder';
import { ResourcePreviewListItemHighlight } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewList/preview/ResourcePreviewListItemHighlight';
import { ResourcePreviewListItemImage } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewList/preview/ResourcePreviewListItemImage';
import { ResourcePreviewListItemNote } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewList/preview/ResourcePreviewListItemNote';
import { ResourcePreviewListItemPage } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewList/preview/ResourcePreviewListItemPage';
import { ResourcePreviewListItemStoredFile } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewList/preview/ResourcePreviewListItemStoredFile';
import {
  isResourceDetailBookmark,
  isResourceDetailFolder,
  isResourceDetailHighlight,
  isResourceDetailImage,
  isResourceDetailNotepad,
} from '@/src/modules/resources/utils/resourceDetailGuards';

const logger = createLogger('Resource list item');

export const ResourcePreviewListPreview: React.FC = () => {
  const { resource } = useResourceDataContextSafe();

  if (resource.fileUrl) {
    return <ResourcePreviewListItemStoredFile />;
  }

  if (isResourceDetailBookmark(resource)) {
    return <ResourcePreviewListItemPage />;
  }

  if (isResourceDetailFolder(resource)) {
    return <ResourcePreviewListItemFolder />;
  }

  if (isResourceDetailHighlight(resource)) {
    return <ResourcePreviewListItemHighlight />;
  }

  if (isResourceDetailImage(resource)) {
    return <ResourcePreviewListItemImage />;
  }

  if (isResourceDetailNotepad(resource)) {
    return <ResourcePreviewListItemNote />;
  }

  logger.error('Unhandled render', resource);

  return null;
};
