import {
  ResourceDetailBookmark,
  ResourceDetailNotepad,
} from '@/src/modules/resources/resources.types';
import { FdocOfType } from '@/src/types/api';

export const resourceDetailsQueryKeys = {
  pagePreview: (resourceId?: string) => ['resource-page-preview', resourceId],
  spotifyPreview: (url?: string) => ['resource-page-preview', url],
  pageMarkdown: (resource?: ResourceDetailBookmark) => [
    'resource-page-markdown',
    resource?.data.webpage?.reader?.url || null,
  ],
  favicon: (url?: string, size?: number) => ['resource-favicon', url, size],

  storedFileDetails: (fdoc?: FdocOfType<'stored_file'>) => [
    'resource-stored-file-details',
    fdoc?.id,
  ],
  imageDetails: (fdoc?: FdocOfType<'image'>) => ['resource-image-details', fdoc?.id],

  ancestors: (resourceId?: string) => ['resource-ancestors', resourceId],
  notepadState: (resourceDetail?: ResourceDetailNotepad) => [
    'resource-notepad-state',
    resourceDetail?.id || null,
  ],
  notepadSync: (resourceDetail?: ResourceDetailNotepad) => [
    'resource-notepad-sync',
    resourceDetail?.id || null,
  ],

  linkPreview: (url?: string) => ['resource-detail-link-preview', url],
};
