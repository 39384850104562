import styled from 'styled-components';

import { TruncatorV2 } from '@/src/components/Truncator/TruncatorV2';
import { useResourceHighlightDataSafe } from '@/src/modules/resource-detail/components/context/resourceDataContext';
import { ColorLabel } from '@/src/modules/resources/components/ResourcePreview/components/ColorLabel';
import { ResourcePreviewFavicon } from '@/src/modules/resources/components/ResourcePreview/components/ResourcePreviewFavicon';
import {
  ResourcePreviewDescription,
  ResourcePreviewTitle,
} from '@/src/modules/resources/components/ResourcePreview/components/Typography';
import { ResourcePreviewLoadingStateGrid } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/components/LoadingState';
import { containerPreviewCompact } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/cssPreviewContainer';
import { cssAspectRatio } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/sharedCss';
import Divider from '@/src/modules/ui/components/Divider';
import { Flex } from '@/src/modules/ui/components/Flex';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { cleanUrl } from '@/src/utils/text';
import React from 'react';

export const ResourcePreviewHighlight: React.FC = () => {
  const { resource, placeholderName } = useResourceHighlightDataSafe();

  const url = resource.originUrl || resource.data.webpage?.url || null;

  const displayUrl = url ? cleanUrl(url) : null;

  const textRef = React.useRef<HTMLDivElement>(null);

  return (
    <Wrapper data-preview-content-wrapper="text">
      <ColorLabel absPosition="inside-top-left" />
      <ResourcePreviewLoadingStateGrid resource={resource} />
      <PageHeader>
        {url && <ResourcePreviewFavicon pageUrl={url} />}
        <PageInfo>
          {displayUrl && (
            <StyledMobileResourcePreviewTitle>{displayUrl}</StyledMobileResourcePreviewTitle>
          )}
          <StyledResourcePreviewTitle>
            {resource.name || placeholderName}
          </StyledResourcePreviewTitle>
          {displayUrl && (
            <StyledResourcePreviewDescription>{displayUrl}</StyledResourcePreviewDescription>
          )}
        </PageInfo>
      </PageHeader>
      <StyledDivider borderColor="secondary" />
      <TextContent ref={textRef}>
        <TruncatorV2 parentRef={textRef}>{resource.data.extensionContext?.text}</TruncatorV2>
      </TextContent>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.5rem;
  overflow: hidden;
  justify-content: center;
  padding: 0.75rem;
  border-radius: 1.125rem;
  position: relative;
  border: 1px solid ${cssVar['color-border-secondary']};
  background: ${cssVar['color-bg-primary']};
  ${cssAspectRatio};

  ${containerPreviewCompact} {
    border: none;
    border-radius: 0;
  }
`;

const StyledResourcePreviewDescription = styled(ResourcePreviewDescription)`
  ${containerPreviewCompact} {
    display: none;
  }
`;
const StyledResourcePreviewTitle = styled(ResourcePreviewTitle)`
  ${containerPreviewCompact} {
    display: none;
  }
`;

const StyledMobileResourcePreviewTitle = styled(ResourcePreviewTitle)`
  display: none;
  ${containerPreviewCompact} {
    display: block;
  }
`;
const StyledDivider = styled(Divider)`
  ${containerPreviewCompact} {
    display: none;
  }
`;

const PageHeader = styled(Flex)`
  gap: 0.5rem;
  width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  ${containerPreviewCompact} {
    flex-direction: column;
    gap: 1rem;
  }
`;
const PageInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  overflow: hidden;
`;

const TextContent = styled.div`
  flex-grow: 1;
  font-size: 0.8125rem;
  color: ${cssVar['color-text-tertiary']};
  overflow: hidden;
  line-height: 1.25rem;

  ${containerPreviewCompact} {
    line-height: 1rem;
    font-size: 0.75rem;
  }
`;
