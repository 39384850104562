import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { useWoody } from '@/src/services/woody/woody';
import { QueryObserverOptions, useQuery } from '@tanstack/react-query';
import { magicQueryKeys } from './magicQueries';
import { SuggestedCategories, SuggestionContext } from '../magic.types';

/**
 * Fetch suggestions for potential tags, new tags or locations for a given context or resource ids.
 *
 * @param [context] - The context data for fetching tag suggestions.
 * @param  [queryOptions] - Optional query options for the useQuery hook.
 * @returns The result of the query.
 */
export const useQueryMagicSuggestions = (
  context?: SuggestionContext,
  queryOptions?: Partial<QueryObserverOptions<SuggestedCategories>>,
) => {
  const { client } = useWoody();
  const query = useQuery({
    queryKey: magicQueryKeys.suggestions(context),
    queryFn: async (params) => {
      const safeSuggestionContext = params.queryKey[1] as SuggestionContext;
      return client.v2('/v2/suggest-categories', { method: 'post', body: safeSuggestionContext });
    },
    staleTime: 0,
    gcTime: 0,
    placeholderData: undefined,
    initialData: undefined,
    persister: undefined, // don't persist
    ...queryOptions,
    enabled: isQueryEnabled([queryOptions?.enabled, !!context]),
  });
  return query;
};
