import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { useWoody } from '@/src/services/woody/woody';
import { toast } from '@/src/store/alerts';
import { useMutation } from '@tanstack/react-query';
import { createOptimisticPrivateTag } from '../utils/createOptimisticPrivateTag';
import { useQueryCacheTagHelpers } from '../utils/useQueryCacheTagHelpers';

type TagCreationActions = 'tags-page' | 'from-resource' | 'suggestion';

type MutationVariables = {
  tagName: string;
  action?: TagCreationActions;
  hideToast?: boolean;
};

export const useMutationTagCreate = () => {
  const { client } = useWoody();
  const { track } = useAnalytics();

  const cacheHelpers = useQueryCacheTagHelpers();

  return useMutation({
    mutationFn: async ({ tagName }: MutationVariables) => {
      return client.v2('/v2/tags', {
        method: 'post',
        body: {
          name: tagName,
        },
      });
    },
    onMutate: async ({ tagName }) => {
      const optimisticTag = createOptimisticPrivateTag(tagName);

      return {
        optimisticTag,
        ...cacheHelpers.addNewTagToCache(optimisticTag),
      };
    },
    onError: (_e, { hideToast = false }, context) => {
      if (!hideToast)
        toast({
          content: 'Something went wrong while creating the tag',
        });

      context?.resetCacheToPreOptimisticState();
      context?.invalidateQueries();
    },
    onSuccess: (tag, { action = 'tabs-page', hideToast = false }, context) => {
      track(AnalyticsEvents.CreatedTag, { action, tagId: tag.id });

      if (!hideToast)
        toast({
          content: 'Tag created successfully',
        });

      cacheHelpers.replaceTagInCache(context.optimisticTag, tag);
    },
  });
};
