import { useResourceDataContextSafe } from '@/src/modules/resource-detail/components/context/resourceDataContext';
import { useQueryResourceAncestors } from '@/src/modules/resource-detail/queries/useQueryResourceAncestors';
import { ResourcePath } from '@/src/modules/resources/components/ResourcePath/ResourcePath';
import { TITLE_SECTION_HEIGHT } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/constants';
import { containerPreviewCompact } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/cssPreviewContainer';
import { cssPreviewShadow } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/sharedCss';
import { Flex } from '@/src/modules/ui/components/Flex';
import { Spinner } from '@/src/modules/ui/components/Spinner';
import { P } from '@/src/modules/ui/components/Typography';
import { cssTextEllipsis, hasHover } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import Tooltip from '@/src/ui/Tooltip';
import React from 'react';
import styled from 'styled-components';

interface ResourcePreviewNameProps {
  spaceTitle?: string | null;
}

export const ResourcePreviewName: React.FC<ResourcePreviewNameProps> = ({ spaceTitle }) => {
  const { resource, placeholderName, fullName } = useResourceDataContextSafe();

  const {
    data: ancestors,
    refetch,
    isFetched,
  } = useQueryResourceAncestors(resource.id, {
    enabled: false,
  });

  return (
    <PillName>
      {spaceTitle && (
        <>
          <Tooltip
            placement="top"
            label={
              isFetched ? (
                <StyledResourcePath path={ancestors || []} />
              ) : (
                <Spinner size={16} color="white" />
              )
            }
            delay={0}
          >
            <Name
              onMouseOver={() => {
                if (!isFetched) {
                  refetch();
                }
              }}
            >
              {spaceTitle}
            </Name>
          </Tooltip>
          <span>/</span>
        </>
      )}
      {resource.name ? (
        <Tooltip
          placement="top"
          label={
            <P color="inherit" size="xxs">
              {fullName}
            </P>
          }
          delay={0}
        >
          <Flex alignItems="center" overflow="hidden">
            <Name>{resource.name || <Placeholder>{placeholderName}</Placeholder>}</Name>
            {resource.extension && <Ext>.{resource.extension}</Ext>}
          </Flex>
        </Tooltip>
      ) : (
        <Flex alignItems="center" overflow="hidden">
          <Placeholder>{placeholderName}</Placeholder>
          {resource.extension && <Ext>.{resource.extension}</Ext>}
        </Flex>
      )}
    </PillName>
  );
};

const Ext = styled.div``;
const Name = styled.div`
  ${cssTextEllipsis};
  &:hover {
    color: ${cssVar['color-app-primary-text']};
  }
`;

const StyledResourcePath = styled(ResourcePath)`
  span {
    color: white;
    font-size: 0.75rem;
  }
  // the initial icon
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

const PillName = styled.div`
  font-size: 0.75rem;
  color: ${cssVar['color-text-quaternary']};
  font-weight: 500;
  padding: 0 0.75rem;
  gap: 0;
  width: max-content;
  max-width: 100%;
  display: flex;
  align-items: center;
  height: ${TITLE_SECTION_HEIGHT}px;
  border: 1px dashed ${cssVar['color-border-primary']};
  border-radius: calc(${TITLE_SECTION_HEIGHT}px / 2);
  gap: 0.25rem;
  flex-shrink: 0;
  transition: 0.1s all;
  background-color: ${cssVar['color-bg-secondary']};

  ${hasHover} {
    &:hover {
      background: ${cssVar['color-bg-primary']};
      color: ${cssVar['color-text-secondary']};
      ${cssPreviewShadow};
    }
  }

  ${containerPreviewCompact} {
    display: none;
  }
`;

const Placeholder = styled(Name)`
  opacity: 0.6;
  pointer-events: none;
`;
