import styled from 'styled-components';

import EditorPreview from '@/src/components/EditorPreview/EditorPreview';

import { useResourceNotepadDataContextSafe } from '@/src/modules/resource-detail/components/context/resourceDataContext';
import { ColorLabel } from '@/src/modules/resources/components/ResourcePreview/components/ColorLabel';
import { ResourcePreviewTitle } from '@/src/modules/resources/components/ResourcePreview/components/Typography';
import { ResourcePreviewLoadingStateGrid } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/components/LoadingState';
import { containerPreviewCompact } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/cssPreviewContainer';
import { cssAspectRatio } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/sharedCss';
import { getResourceNamePlaceholder } from '@/src/modules/resources/utils/getResourceTitle';
import Divider from '@/src/modules/ui/components/Divider';
import { cssVar } from '@/src/modules/ui/theme/variables';

export const ResourcePreviewNotepad: React.FC = () => {
  const { resource } = useResourceNotepadDataContextSafe();

  return (
    <Wrapper data-preview-content-wrapper="notepad">
      <ColorLabel absPosition="inside-top-left" />
      <ResourcePreviewLoadingStateGrid resource={resource} />
      <ResourcePreviewTitle>
        {resource.name || (
          <TitlePlaceholder>{getResourceNamePlaceholder(resource)}</TitlePlaceholder>
        )}
      </ResourcePreviewTitle>
      <StyledDivider borderColor="tertiary" />
      {resource.data.preview ? (
        <StyledEditorPreview data={resource.data.preview} key={resource.id} style={{}} />
      ) : (
        <div></div>
      )}
    </Wrapper>
  );
};

const StyledDivider = styled(Divider)`
  ${containerPreviewCompact} {
    display: none;
  }
`;

const StyledEditorPreview = styled(EditorPreview).attrs({
  withTruncator: true,
})`
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  ${containerPreviewCompact} {
    * {
      font-size: 0.75rem !important;
      line-height: 1rem !important;
    }
  }
`;

const TitlePlaceholder = styled.span`
  opacity: 0.5;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.75rem;
  overflow: hidden;
  padding: 1rem;
  border-radius: 1.125rem;
  position: relative;
  border: 1px solid ${cssVar['color-border-secondary']};
  background: ${cssVar['color-bg-note-primary']};
  ${cssAspectRatio};

  ${containerPreviewCompact} {
    border: none;
    border-radius: 0;
    padding: 0.75rem;
    gap: 0.625rem;
  }
`;
