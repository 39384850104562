import styled from 'styled-components';
import { Flex } from '../../ui/components/Flex';
import ShockIcon from '@/public/images/icons/Shock.svg';
import { cssVar } from '../../ui/theme/variables';
import { P } from '../../ui/components/Typography';
import { SuggestionItemType } from '../magic.types';
import { forwardRef } from 'react';
import FolderIconImg from '@/public/images/icons/Folder.png';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import { useMagicSuggestionsContext } from './MagicSuggestionsContext';

const MagicSuggestionWrapper = styled(motion(Flex)).attrs((p) => {
  return {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
    gap: 'element',
    ...p,
  };
})`
  width: 100%;
  padding: 0 8px;
`;

const MagicSuggestionList = styled(motion(Flex)).attrs((p) => {
  return {
    gap: 'element',
    flexWrap: 'wrap',
    layout: true,
    layoutRoot: true,
    ...p,
  };
})``;

const MagicSuggestionIcon = styled.div.attrs((p) => {
  return {
    children: <ShockIcon />,
    ...p,
  };
})`
  width: 1.5rem;
  height: 1.5rem;
  aspect-ratio: 1/1;

  display: flex;
  align-items: center;
  justify-content: center;
  color: ${cssVar['color-text-tertiary']};
`;

const MagicSuggestionItem = styled(motion.button).attrs((p) => {
  return {
    layout: true,
    initial: { opacity: 1 },
    exit: { opacity: 0 },
    type: 'button',
    ...p,
  };
})`
  user-select: none;
  border: 1px dashed ${cssVar['color-bg-senary']};
  border-radius: ${cssVar['radius-input']};
  padding: 5.5px 10px;
  transition: opacity 0.2s;

  display: flex;
  align-items: center;
  gap: 0.375rem;

  &:hover {
    opacity: 0.8;
  }
`;

const MagicSuggestionText = styled(P).attrs((p) => {
  return {
    as: 'span',
    size: 'xxs',
    color: 'quaternary',
    weight: 500,
    ...p,
  };
})``;

const FolderIcon = styled.img.attrs((p) => {
  return {
    alt: 'Folder',
    ...p,
    src: FolderIconImg.src,
  };
})`
  width: 16px;
  height: auto;
`;

type MagicSuggestionsProps = React.ComponentProps<typeof MagicSuggestionWrapper> & {
  disabled?: boolean;
};

const MagicSuggestions = forwardRef<HTMLDivElement, MagicSuggestionsProps>(
  ({ disabled = false, ...props }, ref) => {
    const { onSelectSuggestion, suggestions, listHash } = useMagicSuggestionsContext();
    const visible = Boolean(suggestions?.length && !disabled);

    return (
      <AnimatePresence mode="popLayout">
        {visible && (
          <MagicSuggestionWrapper key={`magic-suggestion-list-${listHash}`} ref={ref} {...props}>
            <MagicSuggestionIcon />
            <LayoutGroup>
              <MagicSuggestionList>
                <AnimatePresence>
                  {suggestions?.map((el) => (
                    <MagicSuggestionItem
                      key={el.id ?? el.name}
                      onClick={() => onSelectSuggestion(el)}
                    >
                      <MagicSuggestionText>
                        {el.type === SuggestionItemType.FOLDER ? <FolderIcon /> : '#'}
                      </MagicSuggestionText>
                      <MagicSuggestionText>{el.name}</MagicSuggestionText>
                    </MagicSuggestionItem>
                  ))}
                </AnimatePresence>
              </MagicSuggestionList>
            </LayoutGroup>
          </MagicSuggestionWrapper>
        )}
      </AnimatePresence>
    );
  },
);

MagicSuggestions.displayName = 'MagicSuggestions';

export { MagicSuggestions };
