import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type ResourceUiStoreContext = 'global' | 'search' | 'assistant' | 'homeRecent';
export interface ResourcesUiState {
  hideTitle: boolean;
  hideTags: boolean;
  hideComment: boolean;
  zoomLevel: number;
  toggleTitle: VoidFunction;
  togglePinnedComment: VoidFunction;
  toggleTags: VoidFunction;
  contextName: ResourceUiStoreContext;
  gridPreviewDimensions?: {
    height: number;
    width: number;
  };
}

type SetFn = (state: ResourcesUiState) => Partial<ResourcesUiState>;

const createDefaultState =
  (contextName: ResourceUiStoreContext, defaultValues: Partial<ResourcesUiState>) =>
  (set: (state: SetFn) => void): ResourcesUiState => ({
    zoomLevel: 0.9,
    hideTitle: false,
    hideTags: false,
    hideComment: false,
    ...defaultValues,
    contextName,
    toggleTitle: () => set((state) => ({ hideTitle: !state.hideTitle })),
    togglePinnedComment: () => set((state) => ({ hideComment: !state.hideComment })),
    toggleTags: () => set((state) => ({ hideTags: !state.hideTags })),
  });

/**
 * we don't want to flood the store with a lot of properties so choosing approach of having store per context
 *
 * context - configuration per UI context, e.g. search can have different configuration than rest (global)
 */

const useGlobalStore = create<ResourcesUiState>()(
  persist(createDefaultState('global', {}), {
    name: `resource-ui-store-global`,
    version: 0.1,
  }),
);

const useSearchStore = create<ResourcesUiState>()(
  persist(createDefaultState('search', {}), {
    name: `resource-ui-store-search`,
    version: 0.1,
  }),
);
const useAssistantStore = create<ResourcesUiState>()(
  persist(
    createDefaultState('assistant', {
      zoomLevel: 1,
      hideComment: true,
      hideTags: true,
      hideTitle: true,
    }),
    {
      name: `resource-ui-store-assistant`,
      version: 0.1,
    },
  ),
);
const useHomeRecentStore = create<ResourcesUiState>()(
  persist(
    createDefaultState('homeRecent', {
      zoomLevel: 1,
      hideComment: false,
      hideTags: false,
      hideTitle: false,
      gridPreviewDimensions: {
        height: 192,
        width: 168,
      },
    }),
    {
      name: `resource-ui-store-home-recent`,
      version: 0.2,
    },
  ),
);

const store: Record<ResourceUiStoreContext, () => ResourcesUiState> = {
  assistant: useAssistantStore,
  global: useGlobalStore,
  search: useSearchStore,
  homeRecent: useHomeRecentStore,
};

export const useResourcesUiStore = (uiContext: ResourceUiStoreContext = 'global') => {
  const use = store[uiContext];
  return use();
};
