import {
  upsertItemInArrayById,
  useOptimisticallyUpdateInfiniteQueriesWithPredicate,
  useOptimisticallyUpdateQueriesWithPredicate,
} from '@/src/lib/react-query/utilities';
import { resourceQueryPredicates } from '@/src/modules/resources/queries/resourceQueryPredicates';
import { QueryResourceListDataState } from '@/src/modules/resources/queries/useQueryResourceContextSearch';
import { QueryResourceListPage } from '@/src/modules/resources/queries/useQueryResourceList';
import { ResourceDetail } from '@/src/modules/resources/resources.types';
import { ResourceTags } from '@/src/modules/tags/queries/useQueryResourceTags';
import { PrivateTag } from '@fabric/woody-client';
import { useQueryClient } from '@tanstack/react-query';
import { tagsQueryPredicates } from '../queries/tagsQueryPredicates';
import { mutateResourceTag } from './mutateResourceTag';

type TagWithOptionalProperties = Partial<Omit<PrivateTag, 'id'>> & Pick<PrivateTag, 'id'>;

export const useQueryCacheTagHelpers = () => {
  const queryClient = useQueryClient();
  const optimisticallyUpdateQueries = useOptimisticallyUpdateQueriesWithPredicate();
  const optimisticallyUpdateInfiniteQueries = useOptimisticallyUpdateInfiniteQueriesWithPredicate();

  return {
    updateCachedTag: (tagWithUpdatedProperties: TagWithOptionalProperties) => {
      queryClient.cancelQueries({
        predicate: (q) =>
          tagsQueryPredicates.all(q) || tagsQueryPredicates.tag(q, tagWithUpdatedProperties.id),
        type: 'active',
      });

      // updating list of tags where the tag is present
      const optimisticUpdateAllQueries = optimisticallyUpdateQueries<PrivateTag[]>(
        tagsQueryPredicates.all,
        (data) =>
          upsertItemInArrayById(data ?? [], tagWithUpdatedProperties, {
            createIfNotFound: false,
          }),
      );

      // updating the tag directly
      const optimisticUpdateDirectQueries = optimisticallyUpdateQueries<PrivateTag>(
        (q) => tagsQueryPredicates.tag(q, tagWithUpdatedProperties.id),
        (tag) =>
          tag && {
            ...tag,
            ...tagWithUpdatedProperties,
          },
      );

      return {
        optimisticUpdateDirectQueries,
        optimisticUpdateAllQueries,
        resetCacheToPreOptimisticState: () => {
          optimisticUpdateAllQueries.resetQueriesData();
          optimisticUpdateDirectQueries.resetQueriesData();
        },
        invalidateQueries: () => {
          optimisticUpdateAllQueries.invalidateQueries();
          optimisticUpdateDirectQueries.invalidateQueries();
        },
      };
    },
    deleteCachedTag: (tagId: string) => {
      queryClient.cancelQueries({
        predicate: (q) =>
          tagsQueryPredicates.all(q) ||
          tagsQueryPredicates.tag(q, tagId) ||
          tagsQueryPredicates.resourcesAll(q),
        type: 'active',
      });

      const optimisticUpdateAllQueries = optimisticallyUpdateQueries<PrivateTag[]>(
        tagsQueryPredicates.all,
        (data) => data?.filter((t) => t.id !== tagId),
      );

      const optimisticUpdateDirectQueries = optimisticallyUpdateQueries<PrivateTag>(
        (q) => tagsQueryPredicates.tag(q, tagId),
        () => undefined,
      );

      const optimisticUpdateAllResourceQueries = optimisticallyUpdateQueries<ResourceTags[]>(
        tagsQueryPredicates.resourcesAll,
        (data) => {
          if (!data) {
            return;
          }

          return data.map((resource) => ({
            ...resource,
            tags: resource.tags.filter((t) => t.id !== tagId),
          }));
        },
      );

      const optimisticallyUpdateFilterAndSearchResourceQueries =
        optimisticallyUpdateInfiniteQueries<QueryResourceListPage>({
          predicate: resourceQueryPredicates.resourceList,
          pageUpdater: (page) => ({
            ...page,
            resources: page.resources.map((resource) => {
              return {
                ...resource,
                tags: resource.tags.filter((t) => t.id !== tagId),
              };
            }),
          }),
        });

      return {
        optimisticUpdateDirectQueries,
        optimisticUpdateAllQueries,
        optimisticUpdateAllResourceQueries,
        optimisticallyUpdateFilterAndSearchResourceQueries,
        resetCacheToPreOptimisticState: () => {
          optimisticUpdateAllQueries.resetQueriesData();
          optimisticUpdateDirectQueries.resetQueriesData();
          optimisticUpdateAllResourceQueries.resetQueriesData();
          optimisticallyUpdateFilterAndSearchResourceQueries.resetQueriesData();
        },
        invalidateQueries: () => {
          optimisticUpdateAllQueries.invalidateQueries();
          optimisticUpdateDirectQueries.invalidateQueries();
          optimisticUpdateAllResourceQueries.invalidateQueries();
          optimisticallyUpdateFilterAndSearchResourceQueries.invalidateQueries();
        },
      };
    },
    addNewTagToCache: (tag: PrivateTag) => {
      queryClient.cancelQueries({
        predicate: (q) => tagsQueryPredicates.all(q) || tagsQueryPredicates.tag(q, tag.id),
        type: 'active',
      });

      const optimisticUpdateAllQueries = optimisticallyUpdateQueries<PrivateTag[]>(
        tagsQueryPredicates.all,
        (data) =>
          upsertItemInArrayById(data ?? [], tag, {
            createIfNotFound: true,
          }),
      );

      const optimisticUpdateDirectQueries = optimisticallyUpdateQueries<PrivateTag>(
        (q) => tagsQueryPredicates.tag(q, tag.id),
        (data) => ({
          ...data,
          ...tag,
        }),
      );

      return {
        optimisticUpdateDirectQueries,
        optimisticUpdateAllQueries,
        resetCacheToPreOptimisticState: () => {
          optimisticUpdateAllQueries.resetQueriesData();
          optimisticUpdateDirectQueries.resetQueriesData();
        },
        invalidateQueries: () => {
          optimisticUpdateAllQueries.invalidateQueries();
          optimisticUpdateDirectQueries.invalidateQueries();
        },
      };
    },
    replaceTagInCache: (oldTag: PrivateTag, newTag: PrivateTag) => {
      queryClient.cancelQueries({
        predicate: (q) => tagsQueryPredicates.all(q) || tagsQueryPredicates.tag(q, oldTag.id),
        type: 'active',
      });

      const optimisticUpdateAllQueries = optimisticallyUpdateQueries<PrivateTag[]>(
        tagsQueryPredicates.all,
        (data) => data && data.map((t) => (t.id === oldTag.id ? newTag : t)),
      );

      const optimisticUpdateDirectQueries = optimisticallyUpdateQueries<PrivateTag>(
        (q) => tagsQueryPredicates.tag(q, oldTag.id),
        () => newTag,
      );

      return {
        optimisticUpdateDirectQueries,
        optimisticUpdateAllQueries,
        resetCacheToPreOptimisticState: () => {
          optimisticUpdateAllQueries.resetQueriesData();
          optimisticUpdateDirectQueries.resetQueriesData();
        },
        invalidateQueries: () => {
          optimisticUpdateAllQueries.invalidateQueries();
          optimisticUpdateDirectQueries.invalidateQueries();
        },
      };
    },
    assignTagFromResources: (
      tag: PrivateTag,
      resourceIds: string[],
      operation: 'assign' | 'unassign',
    ) => {
      queryClient.cancelQueries({
        predicate: (q) => tagsQueryPredicates.resources(q, resourceIds),
        type: 'active',
      });

      const optimisticUpdateAllQueries = optimisticallyUpdateQueries<ResourceTags[]>(
        (q) => tagsQueryPredicates.resources(q, resourceIds),
        (data) => {
          if (operation === 'assign') {
            const nextData = (data || []).map((resource) => ({
              ...resource,
              tags: [...resource.tags, tag],
            }));

            // add if it doesn't exist
            resourceIds.forEach((resourceId) => {
              if (!nextData.find((item) => item.id === resourceId)) {
                nextData.push({
                  id: resourceId,
                  tags: [tag],
                });
              }
            });
            return nextData;
          } else {
            return (data || []).map((resource) => {
              return {
                ...resource,
                tags: resource.tags.filter((t) => t.id !== tag.id),
              };
            });
          }
        },
      );

      /**
       * update tags per resource in resource list
       */
      const resourceListQueries = optimisticallyUpdateQueries<QueryResourceListDataState>(
        (q) => resourceQueryPredicates.resourceListAny(q),
        (data) => {
          return data
            ? {
                ...data,
                pages: data.pages.map((page) => {
                  return {
                    ...page,
                    resources: page.resources.map((resource) => {
                      if (resourceIds.includes(resource.id)) {
                        return mutateResourceTag(resource, tag, operation);
                      }
                      return resource;
                    }),
                  };
                }),
              }
            : data;
        },
      );

      /**
       * update tags per resource detail
       */
      const resourceDetailQueries = optimisticallyUpdateQueries<ResourceDetail>(
        (q) => resourceQueryPredicates.resourceMultiple(q, resourceIds),
        (data) => {
          return data ? mutateResourceTag(data, tag, operation) : data;
        },
      );

      return {
        optimisticUpdateAllQueries,
        resetCacheToPreOptimisticState: () => {
          optimisticUpdateAllQueries.resetQueriesData();
          resourceListQueries.resetQueriesData();
          resourceDetailQueries.resetQueriesData();
        },
        invalidateQueries: () => {
          optimisticUpdateAllQueries.invalidateQueries();
          resourceListQueries.invalidateQueries();
          resourceDetailQueries.invalidateQueries();
        },
      };
    },
  };
};
